<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Novi korisnik</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Email *"
                required
                :rules="validationRules.email"
                v-model="email"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Lozinka *"
                type="password"
                required
                :rules="validationRules.password"
                v-model="password"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Ponovi lozinku *"
                type="password"
                required
                :rules="validationRules.password_confirmation"
                v-model="password_confirmation"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                label="DPH"
                required
                :items="dphs"
                item-text="Name"
                item-value="Id"
                :rules="validationRules.dph"
                v-model="selected_dph"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <div v-show="loading" style="text-align: center">
      <v-progress-circular indeterminate color="#1358a3"></v-progress-circular>
    </div>
    <p v-show="error" class="error-text">{{ error }}</p>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('closeDialog')"> Zatvori </v-btn>
      <v-btn color="blue darken-1" text @click="save"> Spremi </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";

export default {
  data() {
    return {
      email: null,
      password: null,
      password_confirmation: null,
      selected_dph: null,
      valid: false,
      loading: false,
      error: false,
      validationRules: {
        email: [
          (v) => !!v || "E-mail ne može biti prazan",
          (v) => /.+@.+\..+/.test(v) || "E-mail neispravan",
        ],
        password: [(v) => !!v || "Lozinka ne može biti prazna"],
        password_confirmation: [
          (v) => !!v || "Lozinka ne može biti prazna",
          (v) => v === this.password || "Lozinke moraju biti iste",
        ],
        dph: [(v) => !!v || "DPH nije odabran"],
      },
    };
  },

  async created() {
    await this.$store.dispatch("fetchDphs");
  },

  computed: {
    dphs() {
      return this.$store.state.allDphs;
    },
  },

  methods: {
    async save(e) {
      e.preventDefault();
      this.$refs.form.validate();

      if (this.valid) {
        this.loading = true;
        this.error = false;

        try {
          await apiRequest("post", "users/register", {
            email: this.email,
            password: this.password,
            dph_salesforce_account_id: this.selected_dph,
          });

          this.$emit("newUserSaved");
          this.$refs.form.reset();
        } catch (error) {
          this.error = error.response.data.message;
        } finally {
          this.loading = false;
        }
      }
    },
  },

  mounted() {
    this.valid = false;
  },
};
</script>
