<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <h2>Upravljanje korisnicima</h2>
    </v-card>

    <v-alert type="success" dismissible v-model="alert">
      {{ alertMessage }}
    </v-alert>

    <div class="clearfix">
      <v-btn
        class="new-user-btn"
        color="primary"
        @click="openDialog('newUser')"
      >
        <v-icon small left> mdi-account-plus </v-icon>
        Dodaj novog korisnika
      </v-btn>
    </div>

    <v-dialog v-model="showNewUserDialog" max-width="600px">
      <NewUserDialog
        @closeDialog="closeDialog('newUser')"
        @newUserSaved="newUserSaved"
      />
    </v-dialog>

    <v-dialog v-model="showDeleteUserDialog" width="500">
      <DeleteUserDialog
        :userId="deleteUserId"
        @closeDialog="closeDialog('deleteUser')"
        @userDeleted="userDeleted"
      />
    </v-dialog>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Email korisnika</th>
            <th class="text-left">DPH</th>
            <th class="text-left">Rola</th>
            <th class="text-left">Opcije</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>{{ user.email }}</td>
            <td>{{ user.dph_name }}</td>
            <td v-if="user.is_admin" style="color: #ce3137">Administrator</td>
            <td v-else>Korisnik</td>
            <td>
              <v-btn
                v-if="!user.is_admin"
                @click="openDialog('deleteUser', user._id)"
                small
                depressed
                color="#ff7979"
              >
                <v-icon small left> mdi-account-check </v-icon>
                Obriši
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div v-show="fetchingData" style="text-align: center">
      <v-progress-circular indeterminate color="#1358a3"></v-progress-circular>
    </div>
    <p v-show="error" class="error-text">{{ error }}</p>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";
import NewUserDialog from "../components/users/NewUserDialog.vue";
import DeleteUserDialog from "../components/users/DeleteUserDialog.vue";

export default {
  name: "Users",

  components: {
    NewUserDialog,
    DeleteUserDialog,
  },

  data() {
    return {
      users: [],
      fetchingData: false,
      error: false,
      showNewUserDialog: false,
      showDeleteUserDialog: false,
      deleteUserId: null,
      alert: false,
      alertMessage: null,
    };
  },

  async created() {
    await this.fetchUsers();
  },

  methods: {
    async fetchUsers() {
      this.error = false;
      this.fetchingData = true;

      try {
        this.users = await apiRequest("get", "users");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.fetchingData = false;
      }
    },

    openDialog(dialogName, userId = null) {
      this.alert = false;
      this.alertMessage = "";

      if (dialogName == "newUser") {
        this.showNewUserDialog = true;
      } else if (dialogName == "deleteUser") {
        this.deleteUserId = userId;
        this.showDeleteUserDialog = true;
      }
    },

    closeDialog(dialogName) {
      if (dialogName == "newUser") {
        this.showNewUserDialog = false;
      } else if (dialogName == "deleteUser") {
        this.showDeleteUserDialog = false;
      }
    },

    newUserSaved() {
      this.closeDialog("newUser");
      this.alert = true;
      this.alertMessage = "Korisnik uspješno dodan!";
      this.fetchUsers();
    },

    userDeleted() {
      this.closeDialog("deleteUser");
      this.alert = true;
      this.alertMessage = "Korisnik uspješno izbrisan.";
      this.fetchUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
.new-user-btn {
  float: right;
  margin-bottom: 20px;
}
</style>
