<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Potvrdi brisanje korisnika
    </v-card-title>

    <v-card-text>
      <h3>Jeste li sigurni da želite izbrisati ovog korisnika?</h3>
    </v-card-text>

    <v-divider></v-divider>

    <div v-show="loading" style="text-align: center">
      <v-progress-circular indeterminate color="#1358a3"></v-progress-circular>
    </div>
    <p v-show="error" class="error-text">{{ error }}</p>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="$emit('closeDialog')"> Zatvori </v-btn>
      <v-btn color="red darken-1" text @click="deleteUser"> Izbriši </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";

export default {
  props: ["userId"],

  data() {
    return {
      loading: false,
      error: false,
    };
  },

  methods: {
    async deleteUser() {
      this.loading = true;
      this.error = false;

      try {
        await apiRequest("delete", `users/${this.userId}/delete`);
        this.$emit("userDeleted");
      } catch (error) {
        if (error.message) {
          this.error = error.message;
        } else {
          this.error = error.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 20px;
}
</style>
